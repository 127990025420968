/* Copyright The Linux Foundation and each contributor to LFX.
   SPDX-License-Identifier: MITs */
//
// Visibility utilities
//

.visible {
  @include invisible(visible);
}

.invisible {
  @include invisible(hidden);
}
