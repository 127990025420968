/* Copyright The Linux Foundation and each contributor to LFX.
   SPDX-License-Identifier: MITs */
// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
  display: inline-block;
  font-weight: $btn-font-weight;
  color: $body-color;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid transparent;
  @include button-size(
    $btn-padding-y,
    $btn-padding-x,
    $btn-font-size,
    $btn-line-height,
    $btn-border-radius
  );
  @include transition($btn-transition);

  @include hover {
    color: $body-color;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  // Opinionated: add "hand" cursor to non-disabled .btn elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $link-color;

  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here
}

//
// Button Sizes
//

.btn-lg {
  @include button-size(
    $btn-padding-y-lg,
    $btn-padding-x-lg,
    $btn-font-size-lg,
    $btn-line-height-lg,
    $btn-border-radius-lg
  );
}

.btn-sm {
  @include button-size(
    $btn-padding-y-sm,
    $btn-padding-x-sm,
    $btn-font-size-sm,
    $btn-line-height-sm,
    $btn-border-radius-sm
  );
}

//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type='submit'],
input[type='reset'],
input[type='button'] {
  &.btn-block {
    width: 100%;
  }
}

.lf-primary-btn,
.lf-primary-btn-v2 {
  background-color: #0094ff;
  background-image: none;
  border-radius: 6px;
  border: none;
  color: #ffffff;
  font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
  font-size: 13px;

  &:hover:not([disabled]) {
    cursor: pointer;
    background-color: #0083e2;
  }
}

.lf-primary-btn-v2 {
  background-color: #009ADE;
  color: #ffffff;
  padding: 6px 16px;
  line-height: 16.8px;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;

  a {
    color: inherit;
    &:visited {
      color: inherit;
    }
    &:hover:not([disabled]) {
      cursor: pointer;
      background-color: #009ADE;
      color: inherit;
    }
  
  }

  &:hover:not([disabled]) {
    cursor: pointer;
    background-color: #009ADE;
  }
}

.lf-secondary-btn,
.lf-secondary-btn-v2 {
  border: 1px solid #009ADE;
  border-radius: 6px;
  color: #0094ff;
  background-color: #ffffff;
  font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
  font-size: 13px;

  &:hover:not([disabled]) {
    cursor: pointer;
    text-decoration: underline;
  }
}

.lf-secondary-btn-v2 {
  color: #009ADE;
  padding: 6px 16px;
  line-height: 16.8px;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  a {
    color: inherit;
    &:hover:not([disabled]) {
      cursor: pointer;
      text-decoration: underline;
      color: inherit;
    }
  }
}

.lf-disabled-btn {
  cursor: default;
  font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
  color: #929292;
  background-color: #f7f7f7;
  font-size: 13px;
  border-radius: 6px;
  border: none;

  &:hover {
    text-decoration: none;
    color: #929292;
    background-color: #f7f7f7;
  }
}

.lf-cancel-link {
  font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
  font-size: 13px;
  color: #222222;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
