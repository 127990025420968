/* Copyright The Linux Foundation and each contributor to LFX.
   SPDX-License-Identifier: MITs */
// Text truncate
// Requires inline-block or block for proper styling

@mixin text-truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
