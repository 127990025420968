/* Copyright The Linux Foundation and each contributor to LFX.
   SPDX-License-Identifier: MITs */
// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s or `<ul>`s.

.nav-pills {
  .nav-link {
    @include border-radius($nav-pills-border-radius);
  }

  .nav-link.active,
  .show > .nav-link {
    color: $nav-pills-link-active-color;
    background-color: $nav-pills-link-active-bg;
  }
}

.lf-nav-tabs {
  margin-top: 24px;
  .nav-tabs {
    background-color: inherit;
    width: 99.69%;
    border-width: 0;
    margin-bottom: 24px;
  }

  .tab-content {
    padding: 0;
    background: inherit;
  }

  .nav-tabs .nav-link {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    height: 38px;
    box-sizing: border-box;
    border-width: 0px;
    border-style: solid;
    border-color: rgba(224, 224, 224, 1);
    border-radius: 20px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: Open Sans;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: var(--Black, #00070A);
    text-align: center;
  }

  .nav-tabs .nav-link:not(.active):hover {
    background-color: #f5faff;
  }

  .nav-tabs .nav-link.active {
    box-sizing: border-box;
    border-bottom: 2px solid var(--Blue-500, #009ADE);
    color: #009ADE;
  }
}
