/* Copyright The Linux Foundation and each contributor to LFX.
   SPDX-License-Identifier: MITs */
@mixin badge-variant($bg) {
  color: color-yiq($bg);
  background-color: $bg;

  @at-root a#{&} {
    @include hover-focus {
      color: color-yiq($bg);
      background-color: darken($bg, 10%);
    }
  }
}
