/* Copyright The Linux Foundation and each contributor to LFX.
   SPDX-License-Identifier: MITs */
//
// Screenreaders
//

.sr-only {
  @include sr-only();
}

.sr-only-focusable {
  @include sr-only-focusable();
}
