/* Copyright The Linux Foundation and each contributor to LFX.
   SPDX-License-Identifier: MITs */
/* ========================================================================
     Component: half-float
 ========================================================================== */

//
// Float an absolute positioned element
// and align it with a half top and bottom offset
// relative to its parent
//
// .half-float
//  > .half-float-[top|bottom]
//
// ----------------------------------------------
$half-aligned-size : 128px;
$half-aligned-gutter : 5px;

.half-float {
    position: relative;
    margin-bottom: (calc($half-aligned-size/2)) + $half-aligned-gutter;

    .half-float-bottom,
    .half-float-top {
        position: absolute;
        left: 50%;
        bottom: -(calc($half-aligned-size / 1.6));
        width: $half-aligned-size * 1.25;
        height: $half-aligned-size + $half-aligned-gutter;
        margin-left: -(calc($half-aligned-size * 1.25 / 2));
        z-index: 2;
    }

    .half-float-top {
        bottom: auto;
        top: -(calc($half-aligned-size/2));
    }
}
