/* Copyright The Linux Foundation and each contributor to LFX.
   SPDX-License-Identifier: MITs */
.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}
