// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
//== Vendor

@import 'https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap';
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@600&display=swap');
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~@swimlane/ngx-dnd/release/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/themes/bootstrap.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import '~swiper/swiper-bundle.min.css';

//== Bootstrap

@import './assets/css/bootstrap';

//== Theme

@import './assets/css/themes/material/style.min.css';
@import './assets/css/themes/material/style-responsive.min.css';
@import './assets/css/themes/material/theme/blue.css';

//== Application

@import './assets/css/app';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
