/* Copyright The Linux Foundation and each contributor to LFX.
   SPDX-License-Identifier: MITs */
/*** COLORS ***/
$white: #fff !default;
$black: #000 !default;

$text-color: #252525;
$link-color: #009ADE;

$border-color-light: #8492a6;
$border-color-dark: #002447;

$bg-color-light: #fafafa;
$bg-color-dark: #003366;
$bg-color-moderate-green: #8CC34A;

// Custom colors
$inverse: #fff;
$primary: #009ADE;
$secondary: #8492a6;
$success: #a1d683;
$info: #0099cc;
$warning: #f4b726;
$danger: #ff595a;
$dark: #002447;
$light-grey: #f2f2f2;
$light-blue: #E7ECF0;
$bark-gray: rgb(132, 146, 166);

  /*** FONTS ***/
// $font-family-base: 'Source Sans Pro', sans-serif;
$font-family-base: 'Open Sans', sans-serif;
$font-family-icons: 'Font Awesome 5 Free', 'Font Awesome 5 Pro', 'FontAwesome',
  sans-serif;
$font-family-secondary: $font-family-base;
$font-size-base: .75rem; // ~12px

$text-xs: $font-size-base * 0.825; // ~10px
$text-sm: $font-size-base * 0.9165; // ~11px
$text-md: $font-size-base; // ~12px
$text-lg: $font-size-base * 1.165; // ~14px
$text-xl: $font-size-base * 1.333; // ~16px
$text-xxl: $font-size-base * 1.5; // ~18px

/******************************************/

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$gold: #f4b726 !default;
$gray-767: #eff0f2 !default;
// Custom Gray colors
$gray-darker: #232735;
$gray-dark: #3a3f51;
$gray: #dde6e9;
$gray-light: #e4eaec;
$gray-lighter: #edf1f2;
$gray-800: #424242;
$gray-88: #E0E0E0;
$gray-disabled: #BDBDBD;

// Custom colors
$slider-border: #cecece;
$slider-thumb: #e0e3e7;
$slider-reel: #c5c5c5;
$section-total: #98d377;
$marketing: #3fa9d2;
$mentorship: #ffa356;
$meetsup: #98d377;
$travel: #ff4a53;
$other: #6c3008;
$white-gray: #ddd;
$seperator-color: #f1f1f1;
$section-element: #006996;
$search-background: #0172a4;
$header-dark-blue: #003366;
$header-light-blue: #0099CC;

// Custom section headers colors
$section-bg: #fafafa;
$section-default: #8492a6;
$section-primary: #0099cc;
$section-secondary: #003465;
$section-success: #a0d683;
$section-info: #7464a0;
$section-warning: #ffad5f;
$section-danger: #ff5959;
$section-dark: #3a3f51;
$section-green: #8BC34A;

// Custom badge colors

$badge-diamond: #dddcdc;
$badge-platinum: #b9baba;
$badge-gold: gold;
$badge-silver: #aaa9ad;
$badge-bronze: #ae6842;

// Custom color variants
$amountOfLight: 10%;

$primary-light: lighten($primary, $amountOfLight);
$success-light: lighten($success, $amountOfLight);
$info-light: lighten($info, $amountOfLight);
$warning-light: lighten($warning, $amountOfLight);
$danger-light: lighten($danger, $amountOfLight);
$inverse-light: lighten($inverse, $amountOfLight);
$green-light: lighten($green, $amountOfLight);
$pink-light: lighten($pink, $amountOfLight);
$purple-light: lighten($purple, $amountOfLight);
$yellow-light: lighten($yellow, $amountOfLight);
$primary-dark: darken($primary, $amountOfLight);
$success-dark: darken($success, $amountOfLight);
$info-dark: darken($info, $amountOfLight);
$warning-dark: darken($warning, $amountOfLight);
$danger-dark: darken($danger, $amountOfLight);
$inverse-dark: darken($inverse, $amountOfLight);
$green-dark: darken($green, $amountOfLight);
$pink-dark: darken($pink, $amountOfLight);
$purple-dark: darken($purple, $amountOfLight);
$yellow-dark: darken($yellow, $amountOfLight);

// ---------------------
// Bootstrap 4 Variables Override
// ---------------------

// Color system

$colors: (
  'purple': $purple,
  'pink': $pink,
  'yellow': $yellow,
  'green': $green,
  'primary': $primary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'inverse': $inverse,
  'primary-light': $primary-light,
  'primary-dark': $primary-dark,
  'success-light': $success-light,
  'success-dark': $success-dark,
  'info-light': $info-light,
  'info-dark': $info-dark,
  'warning-light': $warning-light,
  'warning-dark': $warning-dark,
  'danger-light': $danger-light,
  'danger-dark': $danger-dark,
  'green-light': $green-light,
  'green-dark': $green-dark,
  'pink-light': $pink-light,
  'pink-dark': $pink-dark,
  'purple-light': $purple-light,
  'purple-dark': $purple-dark,
  'inverse-light': $inverse-light,
  'inverse-dark': $inverse-dark,
  'yellow-light': $yellow-light,
  'yellow-dark': $yellow-dark,
);

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
);

$border-width: 1px;

// Body

$body-color: #656565;
$body-family: $font-family-base;

$border-radius-sm: 0.85rem;
$border-radius: 0.85rem;
$border-radius-lg: 1.5rem;

$line-height-base: 1.528571429;

$headings-line-height: 1.1;

$text-muted: #909fa7;

// Cards

$card-spacer-y: 1rem;
$card-spacer-x: 1rem;
$card-spacer: 10px;

// Tables

$table-accent-bg: #fafbfc;
$table-hover-bg: #fafbfc;
$table-border-color: #eee;

// Buttons + Forms

$input-btn-padding-y: 0.375rem;
$input-btn-padding-x: 1rem;

$input-btn-padding-y-sm: 0.3125rem;
$input-btn-padding-x-sm: 0.625rem;

$input-btn-padding-y-lg: 0.625rem;
$input-btn-padding-x-lg: 1.25rem;

$input-height: 2.1875rem; // 35px

// Dropdowns

$dropdown-border-color: #e1e1e1;

// Navs

$nav-tabs-link-active-bg: #fff;

// Jumbotron

$jumbotron-bg: #fcfcfc;

// List group

$list-group-border-color: #e4eaec;

// Image thumbnails

$thumbnail-padding: 0.125rem;
$thumbnail-wrap-margin: 0.8rem;

// Breadcrumbs

$breadcrumb-bg: $white;
$breadcrumb-divider-color: #333333;
$breadcrumb-active-color: $body-color;

// ---------------------
// Utilities variables
// ---------------------

$text-alpha: rgba(255, 255, 255, 0.5);
$text-alpha-inverse: rgba(0, 0, 0, 0.5);

$wd-xxs: 60px;
$wd-xs: 90px;
$wd-sm: 150px;
$wd-sd: 200px; // sd == standard
$wd-md: 240px;
$wd-lg: 280px;
$wd-xl: 320px;
$wd-xxl: 360px;
$wd-wide: 100%;
$wd-auto: auto;
$wd-zero: 0;

// ---------------------
// LF Theme
// ---------------------
$border-color: #001e3c;
$border-dark: $border-color;
$border-light: #8492a6;
$dark-bg: #003366;
$light-bg: #fafafa;
$new-light-bg: #F5F5F5;

// SOCIAL IDENTITY BUTTONS COLORS
$success-action-color: #1a8845;
$danger-action-color: #0b6b2d;
$primary-black: #242424;
$darkest-gray: #333333;

// FILTER SEARCH PANEL
$filter-search-border-radius: 3.125rem;

// HIGHLIGHT/HOVER COLORS
$dropdown-hover: #a1d683;

// Griiter colors
$gritter-title: #333;
$gritter-top: #999;
$gritter-top-background: rgba(255, 255, 255, 0.98);
$gritter-close: #ccd0d4;
$gritter-close-hover: #212121;
$gritter-close-light: #444;
$gritter-bottom: rgba(0, 0, 0, 0.8);

// Modals
$modal-background-color: #f5f5f5;

// INPUT
$bg-shadow: 0 1px 5px rgba(104, 104, 104, 0.8);

// Modal Warning
$unavailable-domain: #ff595a;

// Code Contribution
$commits-text-color: #333333;

$grid-gutter-width: 20px;

// severity
$severity-low: #f4b726;
$severity-medium: #ffad5f;
$severity-high: #ff5959;

// legend
$legend-light-text: #8492A6;

$toast-max-width: 405px;

$table-accent-bg: none;


@mixin main-navbar {
  right: 0%;
  position: absolute;
  top: 0;
  z-index: 1040;
  cursor: pointer;
  .nav-tabs {
    height: 50px;
    display: flex !important;
  }

  .nav-item {
    text-align: center;
    .nav-link {
      border-radius: 0;
      border-right: 0;
      color: $blue;
      padding: 5px 10px;
      position: relative;
      text-align: center;

      &.active {
        &:after {
          border-bottom: 3px solid $section-primary;
          bottom: -2px;
          content: '';
          left: 2px;
          position: absolute;
          right: 0;
          width: auto;
        }
      }
    }
  }

  // @media screen and (max-width: 1150px) {
  //   .nav-item {
  //     .nav-link {
  //       padding: 21px 2px;
  //     }
  //   }
  // }

  // @media screen and (max-width: 1070px) {
  //   left: 14%;
  //   top: 52px;
  //   transform: translate(0px, 50%);

  //   .nav-tabs {
  //     height: 0;
  //   }

  //   .nav-item {
  //     .nav-link {
  //       padding: 16px 6px;
  //     }
  //   }
  // }

  @media screen and (max-width: 767px) {
    display: none;
  }
}
