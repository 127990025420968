/* Copyright The Linux Foundation and each contributor to LFX.
   SPDX-License-Identifier: MITs */
/* ========================================================================
         Component: utils
 ========================================================================== */

// Utilities classes to simplify
// components constructions

// Extra Background helpers
@function custom-color($key) {
  @return map-get($colors, $key);
}

@mixin bgcolor-variant($bgcolor, $text-color: #fff) {
  $amountOfLight: 10%;
  & {
    background-color: #2196f3 !important;
    color: $text-color !important;
  }
  &-light {
    $col: custom-color($bgcolor + '-light');
    background-color: #{$col};
    color: $text-color !important;
  }
  &-dark {
    $col: custom-color($bgcolor + '-dark');
    background-color: #{$col};
    color: $text-color !important;
  }
  small {
    color: inherit;
  }
}

@mixin make-bg($bgcolor, $text-color: #fff) {
  background-color: $bgcolor;
  color: $text-color !important;
}

// Common thumbnail sizes (ideal for images)
@mixin thumnbail-size($sz: 8px) {
  width: $sz !important;
  height: $sz !important;
}

// Border
// --------------------

.b0 {
  border-width: 0 !important;
}
.bl0 {
  border-left-width: 0 !important;
}
.br0 {
  border-right-width: 0 !important;
}
.bt0 {
  border-top-width: 0 !important;
}
.bb0 {
  border-bottom-width: 0 !important;
}

.br {
  border-right: 1px solid $border-color;
}
.bl {
  border-left: 1px solid $border-color;
}
.bt {
  border-top: 1px solid $border-color;
}
.bb {
  border-bottom: 1px solid $border-color;
}
.b,
.ba {
  @extend .br;
  @extend .bl;
  @extend .bt;
  @extend .bb;
} // all borders

// Text helpers

.text-primary {
  color: $primary !important;
}
.text-success {
  color: $success !important;
}
.text-info {
  color: $info !important;
}
.text-warning {
  color: $warning !important;
}
.text-danger {
  color: $danger !important;
}

.text-white {
  color: #fff !important;
}
.text-inverse {
  color: $inverse !important;
}
.text-alpha {
  color: $text-alpha !important;
}
.text-pink {
  color: $pink !important;
}
.text-purple {
  color: $purple !important;
}
.text-dark {
  color: $dark !important;
}
.text-alpha-inverse {
  color: $text-alpha-inverse !important;
}
.text-green {
  color: $green !important;
}
.text-yellow {
  color: $yellow !important;
}
.text-gray-darker {
  color: $gray-darker !important;
}
.text-gray-dark {
  color: $gray-dark !important;
}
.text-gray {
  color: $gray !important;
}
.text-gray-light {
  color: $gray-light !important;
}
.text-gray-lighter {
  color: $gray-lighter !important;
}
.text-inherit {
  color: inherit !important;
}

// Text sizing

.text-sm {
  font-size: $text-sm;
}
.text-md {
  font-size: $text-md;
}
.text-lg {
  font-size: $text-lg;
}
.text-xl {
  font-size: $text-xl;
}

// Text others

.text-nowrap {
  white-space: nowrap;
}
.text-thin {
  font-weight: 100 !important;
}
.text-normal {
  font-weight: normal !important;
}
.text-bold {
  font-weight: bold !important;
}

.inline {
  display: inline-block !important;
}
.block-center {
  margin: 0 auto;
}

// Background helpers

.bg-primary {
  @include bgcolor-variant('primary');
}
.bg-success {
  @include bgcolor-variant('success');
}
.bg-info {
  @include bgcolor-variant('info');
}
.bg-warning {
  @include bgcolor-variant('warning');
}
.bg-danger {
  @include bgcolor-variant('danger');
}
.bg-green {
  @include bgcolor-variant('green');
}
.bg-pink {
  @include bgcolor-variant('pink');
}
.bg-purple {
  @include bgcolor-variant('purple');
}
.bg-inverse {
  @include bgcolor-variant('inverse');
}
.bg-yellow {
  @include bgcolor-variant('yellow');
}

.bg-white {
  @include make-bg(#fff, inherit);
}
.bg-gray-darker {
  @include make-bg($gray-darker);
}
.bg-gray-dark {
  @include make-bg($gray-dark);
}
.bg-gray {
  @include make-bg($gray, $body-color);
}
.bg-gray-light {
  @include make-bg($gray-light, $body-color);
}
.bg-gray-lighter {
  @include make-bg($gray-lighter, $body-color);
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-cover {
  background-size: cover;
}

// Common thumbnail sizes (ideal for images)

.thumb8 {
  @include thumnbail-size();
}
.thumb16 {
  @include thumnbail-size(16px);
}
.thumb24 {
  @include thumnbail-size(24px);
}
.thumb32 {
  @include thumnbail-size(32px);
}
.thumb48 {
  @include thumnbail-size(48px);
}
.thumb64 {
  @include thumnbail-size(64px);
}
.thumb80 {
  @include thumnbail-size(80px);
}
.thumb96 {
  @include thumnbail-size(96px);
}
.thumb128 {
  @include thumnbail-size(128px);
}

// Vertical align helpers

.align-middle {
  vertical-align: middle;
}
.align-top {
  vertical-align: top;
}
.align-bottom {
  vertical-align: bottom;
}

// Background image
.bg-center {
  background-position: center center;
  background-size: cover;
}

// List
.list-icon {
  em {
    font-size: $text-lg;
    width: 40px;
    vertical-align: middle;
    margin: 0;
    display: inline-block;
    text-align: center;
    @include transition(all 0.2s);
    line-height: 30px;
  }
  div:hover em {
    transform: scale(3, 3);
  }
}

// Fixed table layout algorithm
.d-table-fixed {
  table-layout: fixed;
}

// Components size

.wd-xxs {
  width: $wd-xxs;
}
.wd-xs {
  width: $wd-xs;
}
.wd-sm {
  width: $wd-sm;
}
.wd-sd {
  width: $wd-sd;
}
.wd-md {
  width: $wd-md;
}
.wd-lg {
  width: $wd-lg;
}
.wd-xl {
  width: $wd-xl;
}
.wd-xxl {
  width: $wd-xxl;
}
.wd-wide {
  width: $wd-wide;
}
.wd-auto {
  width: $wd-auto;
}
.wd-zero {
  width: $wd-zero;
}

// Simulate clickable element
.clickable {
  cursor: pointer;
}

// Absolute center element
// -----------------------------------

.abs-center-container {
  position: relative;
}
.abs-center {
  // width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &.abs-fixed {
    position: fixed;
    z-index: 999;
  }

  &.abs-right {
    left: auto;
    right: 20px;
    text-align: right;
  }

  &.abs-left {
    right: auto;
    left: 20px;
    text-align: left;
  }
}

@media (max-height: 720px) {
  .abs-center {
    position: relative;
  }
}

.link-unstyled {
  text-decoration: none !important;
  outline: none !important;
}

.no-resize {
  resize: none;
  max-width: 100%;
  min-width: 100%;
}

.ie-fix-flex {
  -ms-flex: 0 0 auto;
}

.secondary-font {
  font-family: $font-family-secondary;
}

.lf-link {
  color: #0094ff !important;
  cursor: pointer;
  @include hover {
    text-decoration: underline;
  }
}

.lf-link-v2 {
  color: #009ADE !important;
  cursor: pointer;
  @include hover {
    text-decoration: underline;
  }
}

.lf-pills-container {
  display: flex;
  gap: 10px;
  .lf-pill {
    background-color: #F3F4F4;
    color: #00070A;
    outline: 1px solid #C9CBCE;
  }
  .lf-pill,
  .lf-pill-dark {
    padding: 3.5px 10px;
    border-radius: 15px;
    box-sizing: border-box;
    font-family: OpenSans-Regular, Open Sans, sans-serif;
    line-height: normal;
    font-size: 13px;
    text-align: center;
    min-width: 62px;
  }
  .lf-pill-dark {
    background-color: #DBDCDE;
    outline: none;
    color: #00070A;
  }

  &.wrap-pills {
    flex-wrap: wrap;
  }
}

.lf-icons-group {
  display: flex;
  gap: 6px;

  .lf-icon {
    width: 38px;
    height: 38px;
    border-radius: 100%;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    &:hover {
      background-color: #f5faff;
    }
    i {
      font-size: 18px;
      color: #333;
    }
  }
}

.lf-beta-badge {
  background-color: #008002;
  box-sizing: border-box;
  font-family: 'ArialMT', 'Arial', sans-serif;
  color: #ffffff;
  text-align: center;
  line-height: normal;
  align-self: center;
  border-radius: 20px;
}

.lf-beta-badge.page-badge {
  display: inline-block;
  width: 58px;
  height: 24px;
  font-size: 14px;
  padding-top: 3px;
}

.lf-body-text-v2 {
  font-family:  "OpenSans-Regular", "Open Sans", sans-serif;
  font-size: 14px;
  color: #807f83;
  line-height: normal;
}

.error-message-box {
  line-height: 10;
  width: 100%;
  text-align: center;
}

.lf-grey-500 {
  color: #8A8D94;
}

.lf-grey-700 {
  color: #626469;
}

.lf-stats-container {
  .lf-stats-value {
    font-family: 'Roboto Slab';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28.8px;
  }
  .lf-stats-description {
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px;
  }
}

.lf-search-container {
  position: relative;
  width: 191px;
  .fa-search {
    color: #b1b3b7;
    position: absolute;
    font-size: 1rem;
    margin: 8px 0px 0px 10px;
    z-index: 5;
    background: white;
  }
  input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #dbdcde;
    background-color: #fff;
    font-size: 14px;
    font-weight: normal;
    padding: 4px 16px;
    text-indent: 15px;
    height: 30px;
    &::placeholder {
      color: #b1b3b7;
      font-family: 'Open Sans';
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
    &:focus {
      border: 1px solid #009ade !important;
      box-shadow: none !important;
    }
  }
  .fa-spinner,
  .fa-times {
    position: absolute;
    top: 30%;
    left: auto;
    right: 20px;
  }

  .clear-img {
    position: absolute;
    cursor: pointer;
    z-index: 5;
    right: 5px;
    margin-top: 10px;
  }
}

.lf-btn-download-container {
  display: flex;
  align-items: center;
  color: #626469;
  .fal.fa-arrow-to-bottom {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &:hover {
      cursor: pointer;
      color: #009ade;
    }
  }
}

.lf-employee-info-cell {
  display: flex;
  align-items: center;
  gap: 12px;

  .avatar {
    width: 26px;
    height: 26px;
  }

  .name-title {
    display: flex;
    flex-direction: column;
    gap: 3px;

    .lf-link-v2 {
      font-size: 14px;
    }

    .title {
      font-family: 'OpenSans-Italic', 'Open Sans Italic', 'Open Sans',
        sans-serif;
      font-style: italic;
      color: #807f83;
      line-height: normal;
      font-size: 13px;
    }
  }
}

.lf-stats-container {
  .lf-stats-value {
    font-family: 'Roboto Slab';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28.8px;
  }
  .lf-stats-description {
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px;
  }
}


.is-header-sticky {
  position: sticky;
  z-index: 37;
  background: #ffffff;
  top: 60px;
  padding:  22px 32px;
  border: 1px solid #F3F4F4;
  border-radius: 8px;
  right: 0;
  left: 0;
}

.lf-empty-data-message {
  background: #f3f4f4 !important;
  text-align: center;
  font-style: italic;
  color: #00070a !important;
  height: 52px;
  vertical-align: middle;
  line-height: 52px;
}

