/* Copyright The Linux Foundation and each contributor to LFX.
   SPDX-License-Identifier: MITs */
// Those styles was coming from the template we bought
// Added in overrides file
// This file should not be updated

.overflow-scroll {
  overflow: scroll;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.sidebar .sub-menu {
  display: block;
}
.page-sidebar-minified .sidebar .sub-menu {
  display: none;
}

.angular-calendar-inline {
  margin: 0 -1px -1px;
}
.angular-calendar-inline .cal-month-view .cal-day-cell.cal-today {
  background: none;
}
.angular-calendar-inline
  .cal-month-view
  .cal-day-cell.cal-today
  .cal-day-number {
  background: #2e353c;
  color: #fff !important;
}
.angular-calendar-inline .cal-month-view .cal-day-cell,
.angular-calendar-inline .cal-month-view .cal-cell-top {
  min-height: initial;
}
.angular-calendar-inline .cal-month-view .cal-header .cal-cell:hover,
.angular-calendar-inline .cal-month-view .cal-cell-row:hover {
  background: none;
}
.angular-calendar-inline .cal-month-view .cal-day-number {
  font-size: 12px !important;
  font-weight: 600;
  opacity: 1;
  color: #2d353c;
  margin-right: 3px;
  margin-bottom: 3px;
  margin-top: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 40px;
  text-align: center;
  line-height: 20px;
}
.angular-calendar-inline
  .cal-month-view
  .cal-day-cell.cal-weekend
  .cal-day-number {
  color: #b6c2c9;
}
.angular-calendar-inline .cal-month-view .cal-header .cal-cell {
  font-size: 10px;
  text-transform: uppercase;
  color: #2d353c;
  padding: 5px 10px;
}
.angular-calendar-inline .cal-month-view .cal-day-badge {
  text-indent: 20px;
  width: 5px;
  height: 5px;
  min-width: 3px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  bottom: 25px;
  margin: 0;
  right: 11px;
  background: #ff5b57;
}
.angular-calendar-inline .cal-month-view .cal-cell-top,
.angular-calendar-inline .cal-month-view .cal-cell {
  position: relative;
}
.angular-calendar-inline .cal-month-view .cal-events {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.angular-calendar-inline .cal-month-view .cal-event {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
  margin: 1px;
  background: #e1e1e1;
}
.fc-event,
.fc-event-dot {
  background: #2e353c;
}
.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-popover .fc-header {
  background: #e8ecf1;
  color: #242a30;
  font-weight: 600;
}
.fc-list-table td {
  padding: 5px 10px;
}
.mobile-scroll {
  overflow: scroll;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Styling for the Slim Loading Progress Bar container */
.slim-loading-bar {
  position: fixed;
  margin: 0;
  padding: 0;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 99999;
}
.top .slim-loading-bar {
  top: 0;
}

/* Styling for the Slim Loading Progress Bar itself */
.slim-loading-bar-progress {
  margin: 0;
  padding: 0;
  z-index: 99998;
  background-color: #00acac !important;
  height: 3px !important;
  opacity: 0;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

/* Countdown Timer */
.timer .count-down.medium .digital {
  font-size: 36px;
  color: #fff;
}

/* Ng Datepicker */
.ngb-dp-weekday[_ngcontent-c6] {
  color: #2e353c !important;
  font-size: 11px;
  font-weight: bold;
  font-style: initial !important;
}
.ngb-dp-header[_ngcontent-c3],
.ngb-dp-weekdays[_ngcontent-c6] {
  background-color: #f2f3f4 !important;
}
[_nghost-c3].dropdown-menu {
  border: none;
}
[_nghost-c3].dropdown-menu:focus {
  outline: none !important;
}
.ngb-dp-week[_ngcontent-c6] {
  margin-bottom: 4px !important;
}
[_nghost-c7] > select[_ngcontent-c7] {
  font-size: 12px !important;
}

/* Tags Input */
.tags-input__tag[_ngcontent-c3] {
  padding: 2px 24px 3px 7px !important;
  font-size: 12px !important;
  margin: 2px 5px 2px 0 !important;
  line-height: 16px !important;
}
.tags-input__tag-remove-btn[_ngcontent-c3] {
  font-size: 18px !important;
  margin-right: -20px !important;
  margin-left: 5px !important;
  margin-top: 0 !important;
}

/* header */
.header .navbar-nav > li > .dropdown-menu {
  position: absolute;
}
.dropdown-menu-right.dropdown-menu {
  right: 0 !important;
  left: auto !important;
}

/* Sidebar Minified Menu */
.page-sidebar-minified .sidebar .nav li:hover > div > .sub-menu {
  display: block;
  position: fixed;
  margin-left: 60px;
  margin-top: -40px;
  width: 220px;
  background: #242a31;
  z-index: 1020;
  padding: 9px 0 9px 39px;
}
.page-sidebar-minified .sidebar .nav .sub-menu li {
  position: relative;
}
.page-sidebar-minified .sidebar .nav .sub-menu li:hover > div > .sub-menu {
  margin: 0px;
  left: 100%;
  top: -9px;
  position: absolute;
}
.page-sidebar-minified .sidebar .sub-menu > li:first-child:before {
  top: 11px;
  height: 2px;
  width: 30px;
  left: -39px;
}
.page-sidebar-minified .sidebar .sub-menu > li:first-child + li:before {
  top: -10px;
}
.page-sidebar-minified
  .sidebar
  .sub-menu
  > li:first-child
  + li:last-child:before {
  height: auto;
  bottom: 10px;
}
.page-sidebar-minified .sidebar .nav .sub-menu > li > a .caret {
  position: initial;
  margin: 0;
}
.page-sidebar-minified .sidebar .sub-menu > li:before,
.page-sidebar-minified .sidebar .sub-menu > li > a:after,
.page-sidebar-minified .sidebar .sub-menu > li.has-sub > a:before {
  background: #2d353c;
}

// Override for the default square-looking ngbmodal 'xl'
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1340px !important;
  }
}

.progress {
  height: 2.1875rem;
  border-radius: 0.25rem;
  margin: 0;
  width: 100%;
}
.fees-popup {
  height: 200px;
  border-radius: 3px;
  z-index: 999999;
}
.col {
  padding-left: 10px;
  padding-right: 10px;
}
ngb-accordion.accordion > .card {
  overflow: visible !important;
  border-radius: 0px !important;
  .card-header {
    padding: 5px 10px;
    border-radius: 100px !important;
  }
}
.lfx-toggle-pills-left {
  margin-bottom: 0px;
}

.spinner-icon {
  position: relative;
  display: inline-block;
  width: 1.25em;
  height: 1em;
  &:after {
    background-image: url('/assets/img/spinner.svg');
    content: '';
    position: absolute;
    background-size: contain;
    width: 100%;
    height: 100%;
    left: 0px;
    background-repeat: no-repeat;
    background-position: center;
  }
  &:before {
    content: 'W';
    opacity: 0;
  }
}

.add-address-modal .modal-dialog {
  max-width: 583px;
}

.edit-org-modal .modal-dialog {
  max-width: 583px;
}

.dashboard-event-detail-modal {
  .modal-content {
    width: 695px;
    height: 605px;
    background: #ffffff;
    border: 1px solid #8492a6;
    border-radius: 10px;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.dashboard-speaker-summary-modal {
  .modal-content {
    width: 577px;
    height: 605px;
    background: #ffffff;
    border: 1px solid #8492a6;
    border-radius: 10px;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.definition-of-terms-dialog.modal-dialog {
  margin-top: 223px;
  .modal-content {
    width: 625px;
    padding: 47px 0 25px 0;
    border-radius: 6px;
    border: 1px solid #d7d7d7;
    background-color: #ffffff;
    box-sizing: border-box;
  }
}

.new-widget-dialog.modal-dialog.add-key-contact {
  max-width: 843px;
}

.new-widget-dialog.modal-dialog.edit-key-contact {
  max-width: 600px;
}

.new-widget-dialog.modal-dialog.confirmation-dialog {
  max-width: 400px;
}

.new-widget-dialog.modal-dialog {
  margin-top: 101px;
  max-width: 800px;
  .modal-header {
    box-sizing: border-box;
    padding: 20.5px 24px;
    border-radius: 0;

    font-family: 'RobotoSlab-Medium', 'Roboto Slab Medium', 'Roboto Slab',
      sans-serif;
    font-size: 18px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    .btn-close {
      background: transparent
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23333'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E")
        center/1em auto no-repeat;
    }
  }
  .modal-content {
    width: 100%;
    border: none;
    border-radius: 4px;
    padding: 0;
  }

  .modal-body {
    padding: 25px;

    font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #333333;

    .modal-body-title {
      font-family: 'RobotoSlab-Regular', 'Roboto Slab', sans-serif;
      font-style: normal;
      font-size: 16px;

      color: #333333;
      line-height: normal;
      display: block;
    }
  }

  .modal-footer {
    padding: 16px 20px;
    gap: 15px;
    .cancel-button-secondary {
      padding: 0;
      border: none;
      outline: none;

      height: 38px;

      text-align: center;
      font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
      font-size: 13px;
      color: #0099cc;
      line-height: normal;

      border-radius: 5px;
      cursor: pointer;
      border: 1px solid #d2d2d2;
      box-sizing: border-box;
      background-image: linear-gradient(-180deg, #ffffff 0%, #e6e6e6 100%);
    }
    .cancel-button {
      padding: 0;
      border: none;
      outline: none;

      height: 38px;
      padding: 0px 8px;

      text-align: center;
      font-family: 'Open Sans';
      font-size: 14px;
      color: #009ade;
      line-height: normal;

      cursor: pointer;
      box-sizing: border-box;
      background-image: none;
      background-color: transparent;
    }
    .cancel-button:hover {
      background-color: initial;
    }
    .save-button {
      padding: 0;
      border: none;
      outline: none;

      height: 38px;
      min-width: 100px;
      padding: 0px 16px;

      text-align: center;
      font-family: 'Open Sans';
      font-size: 14px;
      color: #fbfeff;
      line-height: normal;

      border-radius: 5px;
      cursor: pointer;
      background: #009ade;
    }
  }
}

.invite-employee-dialog.modal-dialog {
  margin-top: 101px;
  max-width: 632px;
  .modal-header {
    box-sizing: border-box;
    padding: 20.5px 24px;
    border-radius: 0;

    font-family: 'RobotoSlab-Medium', 'Roboto Slab Medium', 'Roboto Slab',
      sans-serif;
    font-size: 18px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    .btn-close {
      background: transparent
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23333'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E")
        center/1em auto no-repeat;
    }
  }
  .modal-content {
    width: 100%;
    border: none;
    border-radius: 4px;
    padding: 0;
  }

  .modal-body {
    padding: 25px;

    font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #333333;

    .modal-body-title {
      font-family: 'RobotoSlab-SemiBold', 'Roboto Slab SemiBold', 'Roboto Slab',
        sans-serif;
      font-weight: 650;
      font-style: normal;
      font-size: 14px;

      color: #333333;
      line-height: normal;
      display: block;
    }
  }

  .modal-footer {
    padding: 16px 20px;
    gap: 15px;
    .cancel-button-secondary {
      padding: 0;
      border: none;
      outline: none;

      height: 38px;

      text-align: center;
      font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
      font-size: 13px;
      color: #0099cc;
      line-height: normal;

      border-radius: 5px;
      cursor: pointer;
      border: 1px solid #d2d2d2;
      box-sizing: border-box;
      background-image: linear-gradient(-180deg, #ffffff 0%, #e6e6e6 100%);
    }
    .cancel-button {
      padding: 0;
      border: none;
      outline: none;

      height: 38px;
      padding: 0px 8px;

      text-align: center;
      font-family: 'Arial', sans-serif;
      font-size: 13px;
      color: #333333;
      line-height: normal;

      cursor: pointer;
      box-sizing: border-box;
      background-image: none;
      background-color: transparent;
    }
    .cancel-button:hover {
      background-color: initial;
    }
    .save-button {
      padding: 0;
      border: none;
      outline: none;

      height: 38px;
      min-width: 100px;
      padding: 0px 16px;

      text-align: center;
      font-family: 'Arial', sans-serif;
      font-size: 13px;
      font-weight: 400;
      color: #ffffff;
      line-height: normal;

      border-radius: 5px;
      cursor: pointer;
      background: #0094ff;
    }
  }
}

.new-employee-dialog.modal-dialog {
  margin-top: 101px;
  max-width: 800px;
  .modal-header {
    background-image: linear-gradient(0deg, #0099cc 0%, #0086b3 100%);
    box-sizing: border-box;
    padding: 20.5px 24px;
    border-radius: 0;

    font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
    color: #ffffff;
    font-size: 18px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    .btn-close {
      background: transparent
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E")
        center/1em auto no-repeat;
    }
  }
  .modal-content {
    width: 100%;
    border: none;
    border-radius: 4px;
    padding: 0;
  }

  .modal-body {
    padding: 25px;
    .modal-body-title {
      font-family: 'Open Sans Semibold', 'Open Sans', sans-serif;
      font-weight: 600;
      color: #333333;
      text-align: center;
      line-height: normal;
      font-size: 16px;
      display: block;
    }
  }

  .modal-footer {
    padding: 16px 20px;
    gap: 15px;
    .cancel-button {
      padding: 0;
      border: none;
      outline: none;

      height: 38px;
      width: 100px;

      text-align: center;
      font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
      font-size: 13px;
      color: #0099cc;
      line-height: normal;

      border-radius: 5px;
      cursor: pointer;
      border: 1px solid #d2d2d2;
      box-sizing: border-box;
      background-image: linear-gradient(-180deg, #ffffff 0%, #e6e6e6 100%);
    }
    .save-button {
      padding: 0;
      border: none;
      outline: none;

      height: 38px;
      min-width: 100px;
      padding: 0px 16px;

      text-align: center;
      font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
      font-size: 13px;
      color: #ffffff;
      line-height: normal;

      border-radius: 5px;
      cursor: pointer;
      background-image: linear-gradient(-180deg, #0099cc 0%, #0086b3 100%);
    }
  }
}

.upload-sbom-file-dialog.modal-dialog {
  margin-top: 87px;
  max-width: 575px;
  .modal-content {
    border: none;
  }
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #ff595a;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: $body-color;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(37, 37, 37, 0.25);
}

.alert.alert-success {
  width: 75%;
  min-height: 40px;
  background: rgba(161, 214, 131, 0.3);
  border: none;
  border-radius: 5px;
  color: #212121 !important;
  font-family: Open Sans;
  font-size: 12px;
  text-align: left;
  vertical-align: bottom;
  padding-left: 1.375rem;
  padding-right: 2.065rem;
  margin-bottom: 0;
  .close {
    font-size: 32px;
    top: -5px;
    font-weight: 100;
    opacity: 1;
    padding-right: 2.065rem;
    color: #444444;
  }
  .fa-check-circle {
    font-size: 20px;
    color: #a1d683;
  }
  i {
    vertical-align: inherit;
  }
}

ngb-alert {
  margin: 0 auto;
}

.form-control {
  border: 1px solid #8492a6;
}

.ng-select .ng-select-container {
  border: 1px solid #DBDCDE;
}

.compare-by-dropdown {
  .ng-select {
    width: 374px;
  }
  .ng-dropdown-panel.ng-select-bottom {
    margin-top: -3px;
  }
}

.compare-by-select {
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
  .ng-dropdown-panel
    .ng-dropdown-panel-items
    .ng-option.ng-option-selected.ng-option-marked {
    font-weight: bold;
    background-color: transparent;
  }
  input {
    vertical-align: sub;
  }
  .ng-select .ng-select-container {
    width: 375px;
  }
}

.breadcrumb {
  border-radius: 4px;
  border-bottom: 1px solid #e0e0e0;
  background-color: rgba(236, 238, 239, 0);
  box-sizing: border-box;
  font-family: 'OpenSans-SemiBold', 'Open Sans SemiBold', 'Open Sans',
    sans-serif;
  font-weight: 650;
  color: #333333;
  text-align: left;
  line-height: normal;
  padding: 0px 0px 20px 0px;
  margin-bottom: 23px;
  font-size: 13px;
}

.breadcrumb-item.active {
  color: #333333;
}

.breadcrumb > li {
  line-height: normal;
}

.breadcrumb > li a.breadcrumb-item-link {
  color: #0094ff;
  cursor: pointer;
}

.breadcrumb > li a.breadcrumb-item-link:hover {
  text-decoration: underline;
  color: #0094ff;
}

.breadcrumb > li a.breadcrumb-item-link.disabled {
  color: #333333;
  cursor: default;
}

.breadcrumb > li a.breadcrumb-item-link.disabled:hover {
  text-decoration: none;
  color: #333333;
}

.view-profile-offcanvas.offcanvas.offcanvas-end {
  width: 900px;
  padding: 0;
  overflow-y: overlay;
  border: none;
  padding-bottom: 20px;
}

.activities-offcanvas.offcanvas.offcanvas-end {
  width: 600px;
  padding: 0;
  border: none;
  padding-bottom: 20px;
}

.projects-offcanvas.offcanvas.offcanvas-end {
  width: 500px;
  padding: 0;
  border: none;
  padding-bottom: 20px;
}

.board-commitee-offcanvas.offcanvas.offcanvas-end {
  width: 600px;
  padding: 0;
  border: none;
  padding-bottom: 20px;
}

.edit-employee-offcanvas.offcanvas.offcanvas-end {
  width: 900px;
  padding: 0;
  overflow-y: overlay;
  border: none;
}

.project-details-offcanvas.offcanvas.offcanvas-end {
  width: 936px;
  padding: 0;
  overflow-y: overlay;
  border: none;
  background: #f3f4f4;
}
.training-offcanvas.offcanvas.offcanvas-end {
  width: 936px;
  padding: 32px;
  overflow-y: overlay;
  border: none;
  background: #f3f4f4;
}

// Carousel Overrides

.carousel:focus-visible,
.carousel-indicators:focus-visible {
  outline: none;
}

.custom-carousel {
  .carousel-inner {
    padding-bottom: 42px;
    height: 219px;
  }
  .carousel-indicators {
    top: 90%;
    [data-bs-target] {
      box-sizing: content-box;
      flex: 0 1 auto;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background-color: #d7d7d7;
      opacity: 1;
    }
  }

  .carousel-control-next,
  .carousel-control-prev {
    opacity: 1;
  }

  .carousel-control-next {
    .carousel-control-next-icon {
      width: 32px;
      height: 32px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='Layer_1' width='32' height='32' viewBox='0 0 496 496'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%2309c;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M0,248c0,137,111,248,248,248s248-111,248-248S385,0,248,0,0,111,0,248ZM248,32c118.7,0,216,96.1,216,216s-96.1,216-216,216S32,367.9,32,248,128.1,32,248,32Zm86.6,224.5l-115.1,115c-4.7,4.7-12.3,4.7-17,0l-7.1-7.1c-4.7-4.7-4.7-12.3,0-17l99.6-99.4-99.5-99.5c-4.7-4.7-4.7-12.3,0-17l7.1-7.1c4.7-4.7,12.3-4.7,17,0l115.1,115c4.6,4.8,4.6,12.4-.1,17.1Z'/%3E%3C/svg%3E");
    }
  }

  .carousel-control-prev {
    .carousel-control-prev-icon {
      width: 32px;
      height: 32px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='Layer_1' width='32' height='32' viewBox='0 0 496 496'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%2309c;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M496,248C496,111,385,0,248,0S0,111,0,248s111,248,248,248,248-111,248-248Zm-248,216c-118.7,0-216-96.1-216-216S128.1,32,248,32s216,96.1,216,216-96.1,216-216,216Zm-86.6-224.5l115.1-115c4.7-4.7,12.3-4.7,17,0l7.1,7.1c4.7,4.7,4.7,12.3,0,17l-99.6,99.4,99.5,99.5c4.7,4.7,4.7,12.3,0,17l-7.1,7.1c-4.7,4.7-12.3,4.7-17,0l-115.1-115c-4.6-4.8-4.6-12.4,.1-17.1Z'/%3E%3C/svg%3E");
    }
  }

  .carousel-indicators .active {
    background-color: #ffa400;
    opacity: 1;
  }
}

// Toast overrides
.toast-container {
  padding: 31px;
}

.new-employee-toast {
  height: 49px;
  .toast-body {
    padding: 8px 16px;
    height: 49px;
    border-radius: 4px;
    background-color: #333333;
    box-sizing: border-box;
    box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.15);
    font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
    color: #ffffff;
    text-align: left;
    line-height: normal;
    font-size: 13px;
  }
  .fal.fa-times {
    font-size: 27px;
    color: #807f83;
  }
  .invite-sent-text {
    align-self: center;
    display: inline-block;
  }
}

// Forms
.lfx-form-control {
  padding: 2px 2px 2px 10px;
  border-radius: 4px;
  border: 1px solid #d2d2d2;
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
  color: #333333;
  text-align: left;
  font-size: 13px;
  height: 38px;
  margin: 5px 0;
  width: 100%;
}

.lfx-form-control:focus {
  color: #333333;
  background-color: #fff;
  outline: 2px solid #0099cc;
  outline-offset: -1px;
}

.lfx-form-control.error-color,
.lfx-form-control.error-color:focus {
  border: 2px solid rgba(255, 68, 56, 1);
}

.error-hint {
  box-sizing: border-box;
  font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
  text-align: left;
  line-height: normal;
  font-size: 11px;
  color: rgba(255, 68, 56, 1);
}

// Custom Checkbox
.lfx-checkbox-label {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 34px;
  padding-top: 3px;
}

/* Hide the browser's default checkbox */
.lfx-checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(194, 194, 194);
}

/* When the checkbox is checked, add a blue background */
.lfx-checkbox-label input:checked ~ .checkmark {
  background-color: rgb(0, 153, 204);
  border: none;
  border-radius: 2px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.lfx-checkbox-label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.lfx-checkbox-label .checkmark:after {
  left: 7px;
  top: 2px;
  width: 10px;
  height: 16px;
  border: solid white;
  border-width: 0 5px 5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.btn-check:focus + .btn,
.btn:focus {
  background-color: inherit !important;
}

.no-style-table {
  color: white;
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    font-size: 13px;
  }

  td,
  .title {
    padding: 0 5px;
  }

  .title {
    font-size: 13px;
    font-weight: bold;
  }
}

.modal.offcanvas-modal-dialog {
  z-index: 1040;
}

.gap-16 {
  gap: 16px;
}
