/* Copyright The Linux Foundation and each contributor to LFX.
   SPDX-License-Identifier: MITs */
/*
Template Name: Color Admin - Responsive Admin Dashboard Template build with Twitter Bootstrap 4
Version: 4.3.0
Author: Sean Ngu
Website: http://www.seantheme.com/color-admin-v4.3/admin/
*/

.navbar-logo,
.coming-soon .brand .logo,
.login .login-header .brand .logo {
	border-color: #64B5F6 #2196F3 #1976D2;
}
.sidebar .nav > li.active > a, 
.sidebar .nav > li.active > a:focus, 
.sidebar .nav > li.active > a:hover,
.page-sidebar-minified .sidebar .nav > li.has-sub.active:focus > a, 
.page-sidebar-minified .sidebar .nav > li.has-sub.active:hover > a, 
.page-sidebar-minified .sidebar .nav > li.has-sub.active > a,
.label.label-theme,
.btn.btn-scroll-to-top {
	background: #2196F3;
}
.navbar.navbar-inverse .navbar-brand {
	color: #fff !important;
}
.text-theme,
.sidebar .sub-menu > li.active > a:before {
	color: #2196F3;
}
@media (max-width: 767px) {
	.navbar-inverse .navbar-header {
		background: #2196F3;
	}
}


/* Added in v1.5 */

.gradient-enabled .navbar.navbar-inverse .navbar-brand,
.gradient-enabled .sidebar .nav > li.active > a, 
.gradient-enabled .sidebar .nav > li.active > a:focus, 
.gradient-enabled .sidebar .nav > li.active > a:hover,
.gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active:focus > a, 
.gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active:hover > a, 
.gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active > a,
.gradient-enabled .label.label-theme,
.gradient-enabled .btn.btn-scroll-to-top,
.gradient-enabled .top-menu .nav > li.active > a, 
.gradient-enabled .top-menu .nav > li.active > a:focus, 
.gradient-enabled .top-menu .nav > li.active > a:hover {
	background: #64b5f6; /* Old browsers */
	background: -moz-linear-gradient(top,  #64b5f6 0%, #1e88e5 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  #64b5f6 0%,#1e88e5 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  #64b5f6 0%,#1e88e5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#64b5f6', endColorstr='#1e88e5',GradientType=0 ); /* IE6-9 */
}
.pace-progress {
	background: #2196F3;
}
.pace .pace-activity {
	border-top-color: #2196F3;
	border-left-color: #2196F3;
}


/* Added in v1.7 */

.page-with-light-sidebar .sidebar .nav > li.active > a > i {
	color: #2196F3;
}
.page-with-light-sidebar .sidebar .nav > li.active > a .badge,
.page-with-light-sidebar .sidebar .nav > li.active > a .label.label-theme {
	background: #2196F3;
}


/* Added in v1.9 */

.top-menu .nav > li.active > a, 
.top-menu .nav > li.active > a:focus, 
.top-menu .nav > li.active > a:hover {
	background: #2196F3;
}