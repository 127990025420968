/* Copyright The Linux Foundation and each contributor to LFX.
   SPDX-License-Identifier: MITs */
/* Copyright The Linux Foundation and each contributor to LFX.
   SPDX-License-Identifier: MITs */
/*!
 *
 * Community Bridge
 *
 * Author: Ahmed Abdulmoniem
 * Website: https://communitybridge.org
 */

// Bootstrap
@import 'bootstrap/functions';
@import 'bootstrap/variables';
@import 'bootstrap/mixins';

// Variables
@import 'app/variables';

// Layout
@import 'app/layout';

// Common
@import 'app/button-extra';
@import 'app/half-float';
@import 'app/utils';
@import 'app/titles.scss';


//Overrides => This should be always the last section (coming from the theme provider)
@import 'app/overrides';
