/* Copyright The Linux Foundation and each contributor to LFX.
   SPDX-License-Identifier: MITs */
.lf-page-title {
  color: #333333;
  font-family: "RobotoSlab-Bold", "Roboto Slab Bold", "Roboto Slab", sans-serif;
  font-size: 24px;
  text-transform: capitalize;
  text-align: left;
  font-weight: 700;
  line-height: 30px;
}

.lf-title-description {
  color: #807f83;
  font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
  font-size: 14px;
  text-align: left;
}
