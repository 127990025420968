/* Copyright The Linux Foundation and each contributor to LFX.
   SPDX-License-Identifier: MITs */
/*
CommunityBridge Styling Overrides per the Linux Foundation
Author: Bryan Stone
Website: https://communitybridge.org
*/

@import 'variables.scss';

/* START LF Overrides for '1.1 Reset and overrides' */
body {
  background: $white !important;
  font-family: $font-family-base !important;
  color: $text-color !important;
}

*,
.scrollbar-large {
  scrollbar-color: $gray-500 !important;
  scrollbar-width: 1.2rem !important;

  &::-webkit-scrollbar {
    width: 1.2rem !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-500 !important;
  }
}

.h1,
h1 {
  font-size: 2.5rem;
}
.h2,
h2 {
  font-size: 2rem;
}
.h3,
h3 {
  font-size: 1.6rem;
}
.h4,
h4 {
  font-size: 1.4rem;
}
.h5,
h5 {
  font-size: 1.2rem;
}
.h6,
h6 {
  font-size: 1rem;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-size: 60%;
  font-weight: normal;
  color: $text-color;
}
a {
  color: $link-color;
}
a:hover,
a:focus,
a:active {
  color: $link-color;
}
label {
  color: $text-color;
  font-weight: 600;
}
hr {
  border: none;
  height: 1px;
  background: $border-color-dark;
}
/* END LF Overrides for '1.1 Reset and overrides' */

/* START LFX Overrides for '1.2 Font Awesome Version Control' */
.fc button .fc-icon,
.datepicker th.prev,
.datepicker th.next,
.top-menu li > a .caret,
.gallery .rating span.star,
.wysihtml5-toolbar .glyphicon,
.editable-buttons .glyphicon,
.input-append .icon-eye-open,
.input-prepend .icon-eye-open,
.input-append .icon-eye-close,
.input-prepend .icon-eye-close,
.tagit .ui-icon-close:before,
.sidebar li > a .caret:before,
.attached-document > li:before,
.float-sub-menu li > a .caret:before,
.combobox-container .glyphicon-remove,
.combobox-container .icon-remove:before,
.bootstrap-timepicker-widget .glyphicon,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after,
.bootstrap-datetimepicker-widget .glyphicon,
.bootstrap-calendar .calendar .icon-arrow-right,
.bootstrap-calendar .calendar .icon-arrow-left,
.theme-panel .theme-list > li.active > a:before,
.sw-main > ul.step-anchor.nav-tabs > li.done > a .number:before,
.simplecolorpicker.glyphicons span.color[data-selected]:after,
.simplecolorpicker.fontawesome span.color[data-selected]:after,
.jstree-default .jstree-checked > .jstree-checkbox:after,
.jstree-default.jstree-checkbox-selection
  .jstree-clicked
  > .jstree-checkbox:after {
  font-family: $font-family-icons !important;
}
/* END LFX Overrides for '1.2 Font Awesome Version Control' */

/* START LFX Overrides for '2.6 Option - Boxed Layout' */
//.boxed-layout .top-menu,
//.boxed-layout .pace {
//  width: 1500px;
//  margin-left: 0px;
//}
//.boxed-layout .page-container {
//  width: 1500px;
//  margin-left: 0px;
//}
//.boxed-layout .page-container #header {
//  width: 1500px;
//}
/* END LFX Overrides for '2.6 Option - Boxed Layout' */

/* START LFX Overrides for '4.14 Component - Button' */
.btn {
  font-size: $text-md;
  padding: 0.25rem 0.5rem;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 100px;
  min-width: 80px !important;
  line-height: 1rem !important;

  &:focus,
  &:active,
  &:hover {
    outline: none;
    box-shadow: none;
  }

  &.btn-xs {
    font-size: $text-sm;
    padding: 0.25rem 0.5rem;
    line-height: 1.5rem;
  }

  &.btn-sm {
    font-size: $text-md;
    padding: 0.25rem 0.5rem;
    line-height: 1rem;
  }
}
/* START LFX Overrides for '4.14 Component - Button' */

/* START LFX Overrides for '4.14.4 Component - Button - Primary' */
.btn.btn-primary,
.btn.btn-primary.disabled,
.btn.btn-primary.disabled:hover,
.btn.btn-primary.disabled:focus,
.btn.btn-primary[disabled],
.btn.btn-primary[disabled]:hover,
.btn.btn-primary[disabled]:focus {
  color: $white;
  background: $link-color;
  border-color: $link-color;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #fbfeff !important;
  font-family: 'Open Sans' !important;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.show > .btn-primary.dropdown-toggle,
.open > .dropdown-toggle.btn-primary,
.open > .dropdown-toggle.btn-primary:hover,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  background: $link-color;
  border-color: $link-color;
}
.input-group-append .btn.btn-primary,
.btn-group .btn.btn-primary:not(.active) + .btn.btn-primary,
.input-group-btn .btn.btn-primary:not(.active) + .btn.btn-primary {
  border-left-color: $link-color;
}
.input-group-prepend .btn.btn-primary {
  border-right-color: $link-color;
}
/* END LFX Overrides for '4.14.4 Component - Button - Primary' */

.overlay {
  position: absolute;
  top: -0.5rem;
  bottom: -0.5rem;
  right: -0.5rem;
  left: -0.5rem;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  font-weight: bold;
}

/*****************************************************/
.header.navbar-default,
.sidebar {
  background: $new-light-bg;
  border-radius: 0px;

  .overflow-scroll {
    overflow: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
}

.sidebar .nav > li > a,
.header.navbar-default .navbar-nav > li .dropdown-toggle,
.header.navbar-default .navbar-nav > li > a {
  color: $darkest-gray;
}
.header.navbar-default .navbar-nav > li > a {
  height: 50px;
  padding: 12px 15px;
}

.sidebar .nav > li > a,
.sidebar .nav > li > a:focus,
.sidebar .nav > li > a:hover,
.sidebar .sub-menu > li > a,
.sidebar .sub-menu > li > a:focus,
.sidebar .sub-menu > li > a:hover {
  color: #333333;
  font-size: 13px;
  font-family: "Open Sans Semibold", "Open Sans", sans-serif;
}

.sidebar .nav > li.active > a,
.sidebar .nav > li.active > a:focus,
.sidebar .nav > li.active > a:hover,
.sidebar .sub-menu > li.active > a,
.sidebar .sub-menu > li.active > a:focus,
.sidebar .sub-menu > li.active > a:hover {
  background: none;
  color: #333333;
  font-family: "Open Sans Semibold", "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 13px;
}

.header .navbar-brand {
  margin-left: 0.8rem;
  background: url(~/assets/img/logo.svg) no-repeat center !important;
  background-size: 170px !important;
  height: 50px;
}

.wide-fit {
  object-fit: contain !important;

  &.thumb8 {
    width: calc(8px * 1.25) !important;
  }
  &.thumb16 {
    width: calc(16px * 1.25) !important;
  }
  &.thumb24 {
    width: calc(24px * 1.25) !important;
  }
  &.thumb32 {
    width: calc(32px * 1.25) !important;
  }
  &.thumb48 {
    width: calc(48px * 1.25) !important;
  }
  &.thumb64 {
    width: calc(64px * 1.25) !important;
  }
  &.thumb80 {
    width: calc(80px * 1.25) !important;
  }
  &.thumb96 {
    width: calc(96px * 1.25) !important;
  }
  &.thumb128 {
    width: calc(128px * 1.25) !important;
  }
}

.fas {
  &.thumb8 {
    font-size: calc(8px * 0.75) !important;
    padding-top: calc(8px * 0.75 * 0.15);
  }
  &.thumb16 {
    font-size: calc(16px * 0.75) !important;
    padding-top: calc(16px * 0.75 * 0.15);
  }
  &.thumb24 {
    font-size: calc(24px * 0.75) !important;
    padding-top: calc(24px * 0.75 * 0.15);
  }
  &.thumb32 {
    font-size: calc(32px * 0.75) !important;
    padding-top: calc(32px * 0.75 * 0.15);
  }
  &.thumb48 {
    font-size: calc(48px * 0.7525) !important;
    padding-top: calc(48px * 0.75 * 0.15);
  }
  &.thumb64 {
    font-size: calc(64px * 0.75) !important;
    padding-top: calc(64px * 0.75 * 0.15);
  }
  &.thumb80 {
    font-size: calc(80px * 0.7525) !important;
    padding-top: calc(80px * 0.75 * 0.15);
  }
  &.thumb96 {
    font-size: calc(96px * 0.75) !important;
    padding-top: calc(96px * 0.75 * 0.15);
  }
  &.thumb128 {
    font-size: calc(128px * 0.75) !important;
    padding-top: calc(128px * 0.75 * 0.15);
  }
}

.scrollable {
  overflow-y: auto !important;
  height: 300px !important;
  overflow-x: hidden;
}
.fixed-height {
  overflow: hidden !important;
  height: 300px !important;
}

.small {
  font-size: $text-sm;
}

.logo-circle {
  border: 0.25px solid rgba($border-light, 10%);
  -webkit-box-shadow: 3px 3px 3px -2px rgba(209, 209, 209, 0.5);
  -moz-box-shadow: 3px 3px 3px -2px rgba(209, 209, 209, 0.5);
  box-shadow: 3px 3px 3px -2px rgba(209, 209, 209, 0.5);
  object-fit: cover;
}

.lfx-table {
  border-collapse: separate;
  border-spacing: 0 5px;
  thead {
    border: none;
  }
  tr {
    border: none;
  }
  td {
    border-top: solid 1px #d9e0e7 !important;
    border-bottom: solid 1px #d9e0e7 !important;
    background: #ffffff;
    position: relative;
    font-size: 12px;
    color: #252525;
    vertical-align: middle;
    font-weight: normal;
    &:not(:first-of-type):before {
      position: absolute;
      content: '';
      width: 1px;
      top: 5px;
      bottom: 5px;
      background: #d9e0e7;
      left: 0px;
    }
    &:first-child {
      border-left: solid 1px #d9e0e7 !important;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    &:last-child {
      border-right: solid 1px #d9e0e7 !important;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  thead {
    tr {
      th {
        border-top: solid 1px #d9e0e7 !important;
        border-bottom: solid 1px #d9e0e7 !important;
        background: #d9e0e7;
        position: relative;
        font-size: 12px;
        color: #002447;
        font-weight: normal;
        &:not(:first-of-type):before {
          position: absolute;
          content: '';
          width: 1px;
          top: 5px;
          bottom: 5px;
          background: #d9e0e7;
          left: 0px;
        }
        &:first-child {
          border-left: solid 1px #d9e0e7 !important;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        &:last-child {
          border-right: solid 1px #d9e0e7 !important;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }
}

.pagination-value {
  text-align: center;
  span {
    color: $info;
    font-weight: bold;
  }
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background: $gray-400;
  border-radius: 10px;
}
* {
  scrollbar-width: thin;
}
.page-header-fixed,
.header,
.sidebar,
.collapse-btn{
  transition: all 0.1s ease-in;
}
.lfx-main-header-expanded .header {
  top:125px;
}

.lfx-main-header-expanded   .foundation-dropdown {
  top: 140px;
}

.page-header-fixed {
  padding-top: 60px;
}

.sidebar{
  top: 60px;
  padding-top: 0;
}

.lfx-main-header-expanded{
  .page-header-fixed {
    padding-top:  60px;
  }
  .sidebar {
    top: 60px;
  }
}

@media screen and (max-width: 1024px){
  .lfx-main-header-expanded{
    .page-header-fixed {
      padding-top: 60px;
      .content{
        padding-left: 100px !important;
      }
    }
    .sidebar {
      top: 60px;
    }

  }
}

.relative-pos {
  position: relative;
}

.lf-date-range-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;

  .lf-date-range-selector {
    padding: 4px 12px;
    border-radius: 16px;
    background: var(--Grey-100, #DBDCDE);
    cursor: pointer;
    white-space: nowrap;
    span {
      color: #00070a;
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      vertical-align: middle;
    }
  }
  .lf-active-date-range-selector {
    background: #009ade;
    span {
      color: #fff;
    }
  }
}
