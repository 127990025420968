/* Copyright The Linux Foundation and each contributor to LFX.
   SPDX-License-Identifier: MITs */
//
// This file compiles to a custom bootstrap stylesheet for our application
//

// Override bootstrap variables
@import 'app/variables';

// Bootstrap
@import 'bootstrap/bootstrap';
